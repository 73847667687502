<template>
  <div id="page-user-list">
    <div class="flex flex-wrap items-center">
      <!-- ITEMS PER PAGE -->
      <div class="flex-grow">
        <div class="vx-row">
          <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2">
            <statistics-card-line
              hideChart
              class="mb-base"
              icon="PlusCircleIcon"
              icon-right
              :statistic="currency(deposit)"
              statisticTitle="DEPOSIT BALANCE"
              color="warning"
            />
          </div>

          <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2">
            <statistics-card-line
              hideChart
              class="mb-base"
              icon="MinusCircleIcon"
              icon-right
              :statistic="currency(withdraw)"
              statisticTitle="WITHDRAW BALANCE"
              color="danger"
            />
          </div>
        </div>

        <vx-card title="Filter">
          <div class="vx-row">
            <div class="vx-col w-full sm:w-1/4 md:w-1/4 lg:w-1/4 xl:w-1/4">
              <label class="vs-input--label">Start Date</label>
              <flat-pickr
                class="w-full mt-4"
                v-model="startDate"
                :config="config"
              />
              <br />
            </div>
            <div class="vx-col w-full sm:w-1/4 md:w-1/4 lg:w-1/4 xl:w-1/4">
              <label class="vs-input--label">End Date</label>
              <flat-pickr
                class="w-full mt-4"
                v-model="endDate"
                :config="config"
              />
              <br />
            </div>
            <div class="vx-col w-full sm:w-1/4 md:w-1/4 lg:w-1/4 xl:w-1/4">
              <label class="vs-input--label">Type</label>
              <!-- <v-select  v-model="month" :options="monthOptions" name="status"
              :dir="$vs.rtl ? 'rtl' : 'ltr'" /> -->
              <vs-select class="w-full mt-4" v-model="filterType">
                <vs-select-item
                  :key="index"
                  :value="item.val"
                  :text="item.name"
                  v-for="(item, index) in filterSelectType"
                />
              </vs-select>
              <br />
            </div>
            <div class="vx-col w-full sm:w-1/4 md:w-1/4 lg:w-1/4 xl:w-1/4">
              <label class="vs-input--label">Status</label>
              <!-- <v-select  v-model="month" :options="monthOptions" name="status"
              :dir="$vs.rtl ? 'rtl' : 'ltr'" /> -->
              <vs-select class="w-full mt-4" v-model="filterStatus">
                <vs-select-item
                  :key="index"
                  :value="item.val"
                  :text="item.name"
                  v-for="(item, index) in filterSelectStatus"
                />
              </vs-select>
              <br />
            </div>
            <div class="vx-col w-full sm:w-1/6 md:w-1/6 lg:w-1/6 xl:w-1/6">
              <label class="vs-input--label">จำนวนเงิน</label>

              <vs-input
                class="w-full mt-4"
                type="number"
                v-model="startAmont"
              />
              <br />
            </div>
            <label class="vs-input--label mt-12 mr-1">-</label>
            <div class="vx-col w-full sm:w-1/6 md:w-1/6 lg:w-1/6 xl:w-1/6">
              <label class="vs-input--label"></label>
              <vs-input class="w-full mt-4" type="number" v-model="endAmont" />
              <br />
            </div>
            <div class="vx-col w-full sm:w-1/3 md:w-1/3 lg:w-1/3 xl:w-1/3">
              <label class="vs-input--label">Text in table</label>
              <vs-input class="w-full mt-4" type="text" v-model="textSearch" />
              <br />
            </div>
          </div>
          <vs-button class="mr-2" color="success" @click="getDataTransaction()"
            >Search</vs-button
          >
          <vs-button color="primary" @click="clearSearchBox()">Clear</vs-button>
        </vx-card>
        <br />

        <vx-card title="Result">
          <vs-table
            stripe
            max-items="20"
            pagination
            :data="dataTransaction.result"
            search
          >
            <template slot="thead">
              <vs-th>Create Date</vs-th>
              <!-- <vs-th>Transaction Date</vs-th> -->
              <!-- <vs-th>Merchant</vs-th> -->
              <vs-th>Order ID</vs-th>
              <vs-th>Type</vs-th>
              <vs-th>Amount</vs-th>
              <vs-th>MDR</vs-th>
              <vs-th>Net</vs-th>
              <vs-th>Status</vs-th>
              <vs-th>Bank</vs-th>
              <vs-th>Acc No.</vs-th>
              <vs-th>Acc Name</vs-th>
            </template>
            <template slot-scope="{ data }">
              <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                <vs-td :data="tr.created_date">
                  {{ tr.created_date }}
                </vs-td>
                <!-- <vs-td :data="tr.txn_date">
                  {{ tr.txn_date }}
                </vs-td> -->
                <!-- <vs-td :data="tr.merchant_name">
                  {{ tr.merchant_name }}
                </vs-td> -->
                <vs-td :data="tr.order_id">
                  {{ tr.order_id }}
                </vs-td>
                <vs-td :data="tr.transaction_type">
                  {{ tr.transaction_type }}
                </vs-td>
                <vs-td :data="tr.amount">
                  {{ tr.amount }}
                </vs-td>
                <vs-td :data="tr.mdr_amount">
                  {{ tr.mdr_amount }}
                </vs-td>
                <vs-td :data="tr.net_amount">
                  {{ tr.net_amount }}
                </vs-td>
                <vs-td :data="tr.status">
                  <span v-if="tr.status == 'SUCCESS'" class="b b-success">
                    {{ tr.status }}
                  </span>
                  <span v-else-if="tr.status == 'FAILED'" class="b b-failed">
                    {{ tr.status }}
                  </span>
                  <span v-else-if="tr.status == 'CREATE'" class="b b-create">
                    {{ tr.status }}
                  </span>
                  <span v-else class="b">
                    {{ tr.status }}
                  </span>
                </vs-td>
                <vs-td>
                  {{ tr.withdraw_bank_name || tr.deposit_bank_name }}
                </vs-td>

                <vs-td>
                  {{ tr.withdraw_acc_no || tr.deposit_acc_no }}
                </vs-td>

                <vs-td class="mt-2">
                  {{ tr.withdraw_acc_name || tr.deposit_acc_name }}
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </vx-card>
      </div>
    </div>
  </div>
</template>

<script>
import StatisticsCardLine from '@/components/statistics-cards/StatisticsCardLine.vue'
import axios from '../../axios'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
export default {
  components: {
    StatisticsCardLine,
    vSelect,
    flatPickr
  },
  data () {
    return {
      deposit: 0.0,
      withdraw: 0.0,
      textSearch: '',
      startAmont: '',
      endAmont: '',
      startDate: this.moment()
        .subtract(1, 'days')
        .startOf('day')
        .format('YYYY-MM-DD HH:mm:ss'),
      endDate: this.moment().format('YYYY-MM-DD HH:mm:ss'),
      filterType: 0,
      filterSelectType: [
        {
          val: 0,
          name: 'All',
          sentname: 'ALL'
        },
        {
          val: 1,
          name: 'Deposit',
          sentname: 'DEPOSIT'
        },
        {
          val: 2,
          name: 'Withdraw',
          sentname: 'WITHDRAW'
        }
      ],
      filterStatus: 0,
      filterSelectStatus: [
        {
          val: 0,
          name: 'All',
          sentname: 'ALL'
        },
        {
          val: 1,
          name: 'CREATE',
          sentname: 'CREATE'
        },
        {
          val: 2,
          name: 'WAIT_CONFIRM',
          sentname: 'WAIT_CONFIRM'
        },
        {
          val: 3,
          name: 'SUCCESS',
          sentname: 'SUCCESS'
        },
        {
          val: 4,
          name: 'SUCCESS & WAIT_CONFIRM',
          sentname: 'SUCCESS&CONFIRM'
        },
        {
          val: 5,
          name: 'REFUSE',
          sentname: 'REFUSE'
        },
        {
          val: 6,
          name: 'FAILED',
          sentname: 'FAILED'
        },
        {
          val: 7,
          name: 'โยกเข้าร้านค้า',
          sentname: 'MOVE'
        },
        {
          val: 8,
          name: 'จับคู่อัติโนมัติ',
          sentname: 'AUTOMATCH'
        }
      ],
      config: {
        enableTime: true
      },
      dataTransaction: []
    }
  },
  async mounted () {
    await this.getBalance()
    await this.getDataTransaction()
  },
  methods: {
    clearSearchBox () {
      this.startDate = this.moment()
        .subtract(1, 'days')
        .startOf('day')
        .format('YYYY-MM-DD HH:mm:ss')
      this.endDate = this.moment().format('YYYY-MM-DD HH:mm:ss')
      this.filterType = 0
      this.filterStatus = 4
      this.textSearch = ''
      this.startAmont = ''
      this.endAmont = ''
    },
    async getBalance () {
      try {
        const getBalance = await axios
          .post('/payment/api/balance')

        if (getBalance.data.status.success) {
          const { balance, balance_withdraw } = getBalance.data.data
          this.deposit = balance
          this.withdraw = balance_withdraw
        }
      } catch (e) {
        console.error(e)
      }
    },
    async getDataTransaction () {
      axios
        .post('/payment/transaction/getDatatableList', {
          text_search: this.textSearch,
          status_search: this.filterSelectStatus[this.filterStatus].sentname,
          transaction_type_search: this.filterSelectType[this.filterType]
            .sentname,
          deposit_type_search: 'ALL',
          start_date: this.startDate,
          end_date: this.endDate,
          from_txn_amount_search: this.startAmont,
          to_txn_amount_search: this.endAmont
        })
        .then(response => (this.dataTransaction = response.data))
        .catch()
    },
    currency (amount) {
      amount = (amount * 1.0).toFixed(2)
      //SET NULL OR NaN = 0
      if (!amount || amount === 'NaN') {
        amount = 0
      }
      if (amount >= 0) {
        if (typeof amount === 'number') {
          amount = String(Math.abs(amount))
        }
        return amount.replace(/\d(?=(\d{3})+\.)/g, '$&,')
      } else {
        amount = String(amount)
        return amount.replace(/\d(?=(\d{3})+\.)/g, '$&,')
      }
    }
  }
}
</script>

<style scoped>
.b {
  border-radius: 5px;
  padding: 5px; /* Add padding here */
  color: white;
  background-color: #792cffde;
}

.b-success {
  background-color: #03c837de;
}

.b-failed {
  background-color: #ff0000de;
}

.b-create {
  background-color: #ffb300de;
}
</style>
